/*
 * @Author: your name
 * @Date: 2021-12-13 14:13:05
 * @LastEditTime: 2021-12-13 14:44:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Billing/WaybillFee/curd/columns.js
 */
/*
 * @Author: your name
 * @Date: 2021-12-08 18:10:06
 * @LastEditTime: 2021-12-08 18:16:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/columns.js
 */
import moment from "moment"
export default [
    {
        title: "运单号",
        dataIndex: "waybill_no",
        key: "waybill_no",
    },
    {
        title: "运单类型",
        dataIndex: "type",
        key: "type",
        customRender: (text) => {
            return text == 1 ? "FBA运单" : "非FBA运单"
        }
    },
    {
        title: "合计金额",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "包含费用单",
        dataIndex: "confirm_count",
        key: "confirm_count",
    },
    {
        title: "国家或地区",
        dataIndex: "destination",
        key: "destination",
    },

    {
        title: "结算重",
        dataIndex: "bill_weight",
        key: "bill_weight",
    },
    {
        title: "发货渠道",
        dataIndex: "channel_name",
        key: "channel_name",
    },
    {
        title: "下单日期",
        dataIndex: "created_at",
        key: "created_at",
        customRender: (text) => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
        }
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    },

]
