<template>
    <div style="padding:20px" class="page-container">
        <div style="font-size:18px;font-weight:600;margin-bottom:20px" class="header">
            <div>
                <span>账单号：<span>{{No}}</span></span>
            </div>
            <div>
                <span>合计账单金额：<span>{{money}}元</span></span>
            </div>

        </div>
        <a-table bordered size="small" :columns="columns" :data-source="tableData" :scroll="{ x: 'max-content' }" :rowKey="(record,index) => index" :pagination="false" @change="handlerPage">
            <template slot="action" slot-scope="record">
                <a class="btn-a" type="link" @click="check(record)">费用明细</a>
            </template>
        </a-table>
        <a-drawer :maskClosable="false" title="查看费用单" :width="720" :visible="drawer.show" :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
            <div class="box">
                <div class="title">
                    <h1>运单号：{{ bill_no }}</h1>
                    <h1>合计{{ amount }}元</h1>
                </div>
                <div class="table" v-for="(item, index) in infoData" :key="index">
                    <div class="head">
                        <span style="font-weight: bolder">费用{{ index + 1 + ":" + item.amount + "元" }}
                            <span class="verification" v-if="stateColl[index]==1">已核销</span>
                            <span class="un_verification" v-else-if="stateColl[index]==2">未核销</span>
                            <span class="part_verification" v-else>部分核销</span>
                        </span>

                        <span>{{
              moment(item.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")
            }}</span>
                    </div>
                    <a-table size="small" :columns="detColumns" :data-source="item.costs" :scroll="{ x: 'max-content' }" :pagination="false" :customRow="customRow">
                        <template slot="type" slot-scope="record">
                            <span>{{ record == "1" ? "基础运费" : "附加费" }}</span>
                        </template>
                        <span slot="unit" slot-scope="record">
                            {{ record == "1" ? "KG" : "票" }}
                        </span>

                        <span slot="is_write_off" slot-scope="record">
                            {{ record == "1" ? "已核销" : "未核销" }}
                        </span>
                    </a-table>
                </div>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    import {
        billWaybills,
        billWayCosts
    } from "@/api/Billing";
    import columns from "./curd/columns";
    import detColumns from "./curd/detColumns";
    import moment from 'moment'
    export default {
        data() {
            return {
                stateColl: [],
                typeDate: [],
                columns: columns,
                moment,
                tableData: [],
                drawer: {
                    show: false,
                },
                amount: "",
                bill_no: '',
                infoData: {},
                detColumns,
                No:'',
                money:"",
            }
        },
        created() {
            billWaybills({
                billId: Number(this.$route.query.id)
            }).then(res => {
                this.tableData = res.data.list
                this.No = res.data.bill_no 
                this.money =res.data.amount
            })
        },
        computed: {},

        methods: {
            //状态判断
            judge() {
                this.typeDate = []
                setTimeout(() => {
                    this.stateColl = []
                    this.typeDate = []
                    let costs = []
                    for (let i of this.infoData) {
                        costs = []
                        for (let j of i.costs) {
                            costs.push(j.is_write_off)
                        }
                        this.typeDate.push(costs)
                    }
                    for (let i of this.typeDate) {
                        if (i.every(el => el == i[0]) && i[0] == 1) {
                            this.stateColl.push(1)
                            continue
                        }
                        if (i.every(el => el == i[0]) && i[0] == 2) {
                            this.stateColl.push(2)
                            continue
                        }
                        this.stateColl.push(3)
                    }
      
                }, 300)

            },
            check(row) {
                this.drawer.show = true
                billWayCosts({
                    billId: Number(this.$route.query.id),
                    waybillId: row.id
                }).then(res => {
                    this.infoData = res.data
                    this.amount = row.amount
                    this.bill_no = row.waybill_no
                    this.judge()
                   
                })
            },
            handlerPage(p) {
                this.form.page = p.current;
                this.form.limit = p.pageSize;
                this.pagination.current = p.current;
                this.pagination.pageSize = p.pageSize;
                this.getData();
            },
            customRow() {
                return {
                    style: {
                        'font-size': '12px'
                    },
                }
            },
            onClose() {
                this.drawer.show = false;
            },
        },
    }
</script>
<style lang="less" scoped>
    .verification {
        display: inline-block;
        color: #c97482;
        height: 20px;
        width: 60px;
        background: #fee3e4;
        text-align: center;
        border-radius: 15px;
        margin-left: 15px;
    }

    .part_verification {
        display: inline-block;
        color: #f9d27e;
        height: 20px;
        width: 80px;
        background: #fdeaca;
        text-align: center;
        border-radius: 15px;
        margin-left: 15px;
    }

    .un_verification {
        display: inline-block;
        color: #3e95e6;
        height: 20px;
        width: 80px;
        background: #a9d7f7;
        text-align: center;
        border-radius: 15px;
        margin-left: 15px;
    }

    .table {
        .head {
            display: flex;
            justify-content: space-between;
            //   font-weight: bolder;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }

    .box {
        display: block;
        .title {
            display: flex;
            font-size: 10px;
            justify-content: space-between;
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
    }
</style>